.inner {
  margin-right: auto;
  margin-left: auto;
  width: pcw(1240);
  @include sp {
    width: spw(335);
  }
  .heads {
    margin-bottom: pcw(80);
    h3 {
        margin-bottom: 4px;
    }
    .lead {
      margin-bottom: pcw(80);
      font-size: pcw(18);
      font-weight: 500;
      letter-spacing: .2vw;
    }
    @include sp {
      margin-bottom: spw(40);
      .lead {
        margin-bottom: spw(40);
        font-size: spw(16);
      }
    }
  }
}
.cols {
  @include flex;
  @include sp {
    flex-direction: column;
  }
}
h2, h3 {
  @include inter;
}
.btn {
  &-hv-circle {
    width: pcw(200);
    height: pcw(40);
    line-height: pcw(40);
    text-align: center;
    @include inter;
    border-radius: pcw(40);
    position: relative;
    display: block;
    overflow: hidden;
    cursor: pointer;
    &.tra {
      border: 1px solid var(--c-blk);
      &::before {
        background: transparent;
      }
      span {
        color: var(--c-blk);
        font-weight: 500;
      }
    }
    &.arrow {
      span {
        &::after {
          @include content;
          width: pcw(8);
          height: pcw(8);
          background: url(../img/ico/arrow-xs.svg) no-repeat 0 0;
          background-size: contain;
          top: calc(50% - pcw(4));
          right: pcw(20);
          filter: brightness(10);
          @include ts;
        }
      }
      @include sp {
        span {
          &::after {
            top: calc(50% - spw(4));
            right: spw(20);
            width: spw(8);
            height: spw(8);
          }
        }
      }
    }
    span {
      color: var(--c-wht);
      display: block;
      position: relative;
      z-index: 1;
      @include ts;
    }
    &::before {
      @include content;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background: var(--c-blk);
      border-radius: 100px;
      top: 1px;
      left: 1px;
    }
    @include pc {
      &::after {
        @include content;
        width: pcw(200);
        height: pcw(200);
        background: var(--c-grn);
        border-radius: 50%;
        position: absolute;
        top: calc(50% - pcw(100));
        left: calc(50% - pcw(100));
        transform: scale(0);
        @include ts($duration: .6s);
        transition-timing-function: linear;
      }
      &:hover {
        &::after {
          transform: scale(2);
        }
        &.arrow {
          span {
            &::after {
              filter: brightness(0);
            }
          }
        }
        span {
          color: var(--c-blk);
        }
      }
    }
    @include sp {
      width: 100%;
      height: spw(40);
      line-height: spw(40);
      // background: var(--c-blk);
      border-radius: spw(40);
      &.arrow {
        // width: spw(140);
        span {
          padding-right: spw(20);
        }
      }
    }
  }
}
.txt {
  &-notice {
    font-size: max(pcw(12), 12px);
    @include sp {
      font-size: max(spw(12), 12px);
    }
  }
}
.jobs {
  @include flex($h: flex-start);
  gap: 0 pcw(40);
  position: relative;
  @include pc {
    .error {
      position: absolute;
      top: 2vw;
      left: 0;
    }
  }
  @include sp {
    flex-direction: column;
    span {
      margin: 0 0 6px !important;
      width: 100%;
    }
    label {
      width: 100%;
      span {
        padding: 0 spw(30) 0 0;
        display: block;
        &::before {
          right: 0;
          left: initial;
        }
        &::after {
          right: spw(3);
          left: initial;
        }
      }
    }
  }
}
.wp-pagenavi {
  padding: pcw(40) pcw(80);
  font-size: pcw(16);
  @include inter;
  border-bottom: 1px solid var(--c-gry);
  position: relative;
  @include flex($h: center);
  gap: pcw(40);
  @include sp {
    padding: spw(20) spw(80);
    font-size: spw(16);
    gap: spw(20);
  }
  .previouspostslink,
  .nextpostslink {
    color: var(--c-blk);
    position: absolute;
    top: pcw(40);
    @include ts;
    @include sp {
      top: spw(20);
    }
    &::after {
      @include content;
      width: pcw(20);
      height: pcw(20);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 100% auto;
      top: calc(50% - pcw(10));
      filter: brightness(0);
      @include ts;
      @include sp {
        width: spw(20);
        height: spw(20);
        top: calc(50% - spw(10));
      }
    }
    @include pc {
      &:hover {
        color: var(--c-grn);
        &::after {
          filter: brightness(1);
        }
      }
    }
  }
  .previouspostslink {
    margin-right: auto;
    padding-left: pcw(25);
    left: 0;
    &::after {
      background-image: url(../img/ico/arrow-h-grn.svg);
      left: 0;
      transform: rotate(180deg);
    }
    @include sp {
      padding-left: spw(25);
    }
  }
  .nextpostslink {
    margin-left: auto;
    padding-right: pcw(25);
    right: 0;
    &::after {
      background-image: url(../img/ico/arrow-h-grn.svg);
      right: 0;
    }
    @include sp {
      padding-right: spw(25);
    }
  }
  a {
    color: var(--c-gry);
    @include ts;
    @include pc {
      &:hover {
        color: var(--c-grn);  
      }
    }
  }
  .current {
    color: var(--c-blk);
  }
}