@include pc {
    @keyframes ani-scr {
        // width: pcw(237);
        // left: pcw(64);
        0% {
            width: 0;
            left: pcw(64);
            // opacity: 1;
        }
        30% {
            width: pcw(137);
            left: pcw(64);
            // opacity: 1;
        }
        60% {
            width: pcw(137);
            left: pcw(164);
            // opacity: 1;
        }
        // 66% {
        //     width: pcw(100);
        //     left: pcw(201);
        //     opacity: 1;
        // }
        100% {
            width: 0;
            left: pcw(301);
            // opacity: 0;
        }
        // 50% {
        //     width: pcw(177);
        //     left: pcw(120);
        //     opacity: 1;
        // }
        // 100% {
        //     width: pcw(0);
        //     left: pcw(300);
        //     opacity: 0;
        // }
    }
}
@include sp {
    @keyframes ani-scr {
        0% {
            width: spw(0);
            left: spw(64);
            opacity: 0;
        }
        50% {
            width: spw(177);
            left: spw(120);
            opacity: 1;
        }
        100% {
            width: spw(0);
            left: spw(300);
            opacity: 0;
        }
    }
}
@keyframes ani-grad {
    0% {
        top: -100%
    }
    50% {
        top: 0%
    }
    100% {
        top: 100%
    }
}
@keyframes ani-mask {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    50% {
        clip-path: polygon(0 0, 100% 0, 100% 120%, 0 120%);
    }
    100% {
        clip-path: polygon(100% 0, 100% 0, 100% 120%, 100% 120%);
    }
}
@keyframes ani-mouseover {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
@keyframes ani-mouseout {
    0% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
}
@keyframes ani-intro {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 110% 0, 110% 100%, 0 100%);
    }
}
@keyframes ani-intro-above {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    50% {
        clip-path: polygon(0 0, 110% 0, 110% 100%, 0 100%);
    }
    100% {
        clip-path: polygon(110% 0, 110% 0, 110% 100%, 110% 100%);
        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
@keyframes ani-intro-below {
    0% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    50% {
        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
    100% {
        clip-path: polygon(0 0, 110% 0, 110% 100%, 0 100%);
        // clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }
}