@mixin inter($w: 600) {
	font-family: "Inter", sans-serif;
	font-optical-sizing: auto;
	font-weight: $w;
	font-style: normal;
	font-variation-settings: "slnt" 0;
}
@mixin sp($w: $bp) {
	@media screen and (max-width: $w) {
		@content;
	}
}
@mixin pc($w: $bp) {
	@media screen and (min-width: $w + 1) {
		@content;
	}
}
@mixin max-ratio($r: '16/9') {
	@media (max-aspect-ratio: $r) {
		@content;
	}
}
@mixin min-ratio($r: '16/9') {
	@media (min-aspect-ratio: $r) {
		@content;
	}
}
@mixin flex($wrap: wrap, $h: space-between, $v: center) {
	display: flex;
	flex-wrap: $wrap;
	justify-content: $h;
	align-items: $v;
}
@mixin ts($property: all, $duration: .3s, $delay: 0s) {
	transition: $property $duration $delay;
}
@mixin content($position: absolute) {
	content: "";
	display: block;
	position: $position;
}