// .is-intro-animation {
//     .raw {
//         .is-element {
//             animation-delay: .8s;
//             clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
//         }
//         .is-animation {
//             animation: ani-intro .8s forwards;
//         }
//     }
//     .lead {
//         .is-element {
//             transform: translateY(3em);
//             @include ts;
//         }
//         .is-animation {
//             transform: translateY(0);
//         }
//     }
// }
// header {
//     .is-overflow {
//         overflow: hidden;
//         span, a {
//             display: inline-block;
//             transform: translateY(0);
//             @include ts;
//         }
//     }
// }
// section {
//     * {
//         @include ts;
//     }
// }
// .is-noscr {
//     background: var(--c-b-gry);
//     overflow: hidden;
//     &::before {
//         opacity: 0;
//     }
//     &::after {
//         opacity: 1;
//     }
//     header {
//         .is-overflow {
//             span, a {
//                 transform: translateY(3em);
//             }
//         }
//     }
//     .intro {
//         .scr {
//             opacity: 0;
//         }
//     }
//     section {
//         &:not(.intro) {
//             * {
//                 opacity: 0;
//             }
//         }
//     }
// }
body {
    &::after {
        @include content;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: var(--c-b-gry);
        opacity: 1;
        @include ts();
        z-index: 9999;
    }
    &.ready {
        &::after {
            opacity: 0;
            pointer-events: none;
            z-index: -1;
        }
    }
}
.bg-grn {
    // &::before {
        // content: "";
        // display: block;
        // width: 100%;
        // height: 100vh;
        // position: fixed;
        // top: 0;
        // left: 0;
        // background: radial-gradient(var(--c-grn) 0%, transparent 80%);
        // z-index: -1;
        // opacity: 1;
    // }
}
.capability {
    .intro {
        z-index: 5;
        .scr {
            @include ts($duration: 1s, $delay: 1s);
            opacity: 1;
        }
    }
    section {
        &:not(.intro) {
            @include ts($duration: 1s, $delay: 1s);
            opacity: 1;
        }
    }
}
.mask {
    width: 100vw;
    height: 100vh;
    background: var(--c-blk);
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    &::before, &::after {
        @include content;
        width: pcw(56);
        height: 1px;
        background: var(--c-wht);
        position: fixed;
        top: pcw(40);
        right: pcw(80);
        transform: scale(0) rotate(0);
        @include ts;
    }
    @include sp {
        &::before, &::after {
            width: spw(36);
            top: spw(30);
            right: spw(20);
        }
    }
}
.is-modal {
    padding: pcw(48) pcw(100);
    width: pcw(880);
    // height: pcw(584);
    background: var(--c-b-gry);
    border-radius: pcw(28);
    position: fixed;
    top: calc(50% - pcw(292));
    left: calc(50% - pcw(440));
    @include sp {
        padding: spw(40) spw(10) spw(20);
        width: spw(338);
        // height: 80vh;
        top: calc(50% - 40vh);
        left: calc(50% - spw(169));
    }
}
.mask, .is-modal {
    opacity: 0;
    pointer-events: none;
    @include ts;
    &.is-show {
        opacity: 1;
        pointer-events: initial;
    }
}
.mask {
    &.is-show {
        opacity: .7;
        &::before {
            transform: scale(1) rotate(30deg);
        }
        &::after {
            transform: scale(1) rotate(-30deg);
        }
    }
}
.is-cursor {
    width: pcw(20);
    height: pcw(20);
    background: var(--c-pnk);
    border-radius: 50%;
    transform: translate(pcw(-10), pcw(-10));
    pointer-events: none;
    position: fixed;
    &.delay {
        width: pcw(40);
        height: pcw(40);
        // opacity: 0.2;
        transform: translate(pcw(-20), pcw(-20));
        transition-duration: .8s;
        transition-timing-function: ease-out;
    }
}
.is-txt-mask {
    position: relative;
    display: inline-block;
    .is-mask {
        color: var(--c-grn);
        position: absolute;
        top: 0;
        left: 0;
        text-shadow:
            -0.1px -0.1px var(--c-grn),
            0.1px -0.1px var(--c-grn),
            -0.1px -0.1px var(--c-grn),
            -0.1px 0.1px var(--c-grn);
        clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
    .last-circle {
        &::before {
            @include content;
            width: pcw(22);
            height: pcw(22);
            background: var(--c-b-gry);
            right: pcw(18);
            bottom: pcw(49);
            @include sp {
                width: spw(14);
                height: spw(14);
                right: spw(9);
                bottom: spw(14);
            }
        }
        &::after {
            @include content;
            width: pcw(19);
            height: pcw(19);
            background: var(--c-blk);
            border-radius: 50%;
            right: pcw(19);
            bottom: pcw(49);
            @include sp {
                width: spw(10);
                height: spw(10);
                right: spw(12);
                bottom: spw(14);
            }
        }
    }
    &.is-show {
        .is-catch {
            &:not(.last-circle) {
                &:first-child {
                    span {
                        &:last-child {
                            color: var(--c-grn);
                            transition-delay: .5s;
                        }
                    }
                }
            }
            &.last-circle {
                &::after {
                    background: var(--c-grn);
                    transition-delay: .5s;
                }
            }
        }
        .is-mask {
            animation: ani-mask .7s cubic-bezier(.54,.09,.89,.35) forwards;
        }
    }
}
.intro {
    .raw {
        position: relative;
        display: inline-block;
            .c-grn {
            color: var(--c-grn);
            position: absolute;
            top: 0;
            left: 0;
        }
        .is-catch {
            font-family: inherit;
            font-weight: inherit;
            display: inline-block;
        }
        &:nth-child(1) {
            .is-catch {
                .kerning {
                    &:nth-child(1) { letter-spacing: pcw(-6); } //C
                    &:nth-child(2) { letter-spacing: pcw(-6); } //r
                    &:nth-child(3) { letter-spacing: pcw(-6); } //e
                    &:nth-child(4) { letter-spacing: pcw(-7); } //a
                    &:nth-child(5) { letter-spacing: pcw(-8); } //t
                    &:nth-child(6) { letter-spacing: pcw(-5); } //e
                    @include sp {
                        &:nth-child(1) { letter-spacing: spw(-4); } //C
                        &:nth-child(2) { letter-spacing: spw(-3); } //r
                        &:nth-child(3) { letter-spacing: spw(-4); } //e
                        &:nth-child(4) { letter-spacing: spw(-5); } //a
                        &:nth-child(5) { letter-spacing: spw(-5); } //t
                        &:nth-child(6) { letter-spacing: spw(-5); } //e
                    }
                }
            }
        }
        &:nth-child(2) {
            .is-catch {
                .kerning {
                    &:nth-child(1) { letter-spacing: pcw(-7); } //t
                    &:nth-child(2) { letter-spacing: pcw(-6); } //h
                    &:nth-child(3) { letter-spacing: pcw(-14); } //e
                    // &:nth-child(4) {} //
                    &:nth-child(5) { letter-spacing: pcw(-8); } //N
                    &:nth-child(6) { letter-spacing: pcw(-9); } //e
                    &:nth-child(7) { letter-spacing: pcw(-5); } //w
                    &:nth-child(8) { letter-spacing: pcw(-7); } //m
                    &:nth-child(9) { letter-spacing: pcw(-12); } //a
                    &:nth-child(10) { letter-spacing: pcw(-6); } //r
                    &:nth-child(11) { letter-spacing: pcw(-6); } //k
                    &:nth-child(12) { color: var(--c-grn); } //.
                    @include sp {
                        &:nth-child(1) { letter-spacing: spw(-4); } //t
                        &:nth-child(2) { letter-spacing: spw(-4); } //h
                        &:nth-child(3) { letter-spacing: spw(-14); } //e
                        // &:nth-child(4) {} //
                        &:nth-child(5) { letter-spacing: spw(-4); } //N
                        &:nth-child(6) { letter-spacing: spw(-4); } //e
                        &:nth-child(7) { letter-spacing: spw(-3); } //w
                        &:nth-child(8) { letter-spacing: spw(-4); } //m
                        &:nth-child(9) { letter-spacing: spw(-5); } //a
                        &:nth-child(10) { letter-spacing: spw(-2); } //r
                        &:nth-child(11) { letter-spacing: spw(-2); } //k
                        &:nth-child(12) { color: var(--c-grn); } //.
                    }
                }
            }
        }
    }
    .lead {
        .is-catch {
            .kerning {
                &:nth-child(1) { letter-spacing: 1.5px; } //本
                &:nth-child(2) { letter-spacing: 1.5px; } //質
                &:nth-child(3) { letter-spacing: 1.5px; } //を
                &:nth-child(4) { letter-spacing: 1.5px; } //捉
                &:nth-child(5) { letter-spacing: 1.5px; } //え
                &:nth-child(6) { letter-spacing: -5px; } //、
                &:nth-child(7) { letter-spacing: 1.5px; } //新
                &:nth-child(8) { letter-spacing: 1.5px; } //し
                &:nth-child(9) { letter-spacing: 1.5px; } //い
                &:nth-child(10) { letter-spacing: 1.5px; } //価
                &:nth-child(11) { letter-spacing: 1.5px; } //値
                &:nth-child(12) { letter-spacing: 1.5px; } //を
                &:nth-child(13) { letter-spacing: 1.5px; } //定
                &:nth-child(14) { letter-spacing: 1.5px; } //義
                &:nth-child(15) { letter-spacing: 1.5px; } //し
                &:nth-child(16) { letter-spacing: 1.5px; } //て
                &:nth-child(17) { letter-spacing: 1.5px; } //い
                &:nth-child(18) { letter-spacing: 1.5px; } //く
                &:nth-child(19) { letter-spacing: 1.5px; } //。
            }
        }
    }
}
.page-index,
.page-about {
    .is-catch {
        .kerning {
            &:nth-child(1) { letter-spacing: -3px; } //W
            &:nth-child(2) { letter-spacing: -4px; } //h
            &:nth-child(3) { letter-spacing: -4px; } //a
            &:nth-child(4) { letter-spacing: -2px; } //t
            &:nth-child(5) { letter-spacing: -13px; } //'
            &:nth-child(6) { letter-spacing: -8px; } //s
            // &:nth-child(7) {} // 
            &:nth-child(8) { letter-spacing: -4px; } //u
            &:nth-child(9) { letter-spacing: -4px; } //p
            // &:nth-child(10) {} //?
            @include sp {
                &:nth-child(1) { letter-spacing: -2px; } //W
                &:nth-child(2) { letter-spacing: -3px; } //h
                &:nth-child(3) { letter-spacing: -3px; } //a
                &:nth-child(4) { letter-spacing: -2px; } //t
                &:nth-child(5) { letter-spacing: -9px; } //'
                &:nth-child(6) { letter-spacing: -7px; } //s
                // &:nth-child(7) {} // 
                &:nth-child(8) { letter-spacing: -3px; } //u
                &:nth-child(9) { letter-spacing: -3px; } //p
                // &:nth-child(10) {} //?
            }
        }
    }
}
.page-works {
    .is-catch {
        .kerning {
            letter-spacing: -4px;
        }
    }
}
.page-recruit {
    .is-catch {
        .kerning {
            &:nth-child(1) { letter-spacing: -3px; } //J
            &:nth-child(2) { letter-spacing: -3px; } //o
            &:nth-child(3) { letter-spacing: -3px; } //i
            &:nth-child(4) { letter-spacing: -8px; } //n
            // &:nth-child(5) {} //
            &:nth-child(6) { letter-spacing: -3px; } //u
            &:nth-child(7) { letter-spacing: -1px; } //p
            // &:nth-child(8) {} //.
        }
    }
}
.page-company {
    .is-catch {
        .kerning {
            letter-spacing: -4px;
        }
    }
}
.page-contact {
    .is-catch {
        .kerning {
            &:nth-child(1) { letter-spacing: -3px; } //G
            &:nth-child(2) { letter-spacing: -4px; } //e
            &:nth-child(3) { letter-spacing: -6px; } //t
            // &:nth-child(4) { letter-spacing: -1px; } // 
            &:nth-child(5) { letter-spacing: -3px; } //i
            &:nth-child(6) { letter-spacing: -8px; } //n
            // &:nth-child(7) { letter-spacing: -1px; } // 
            &:nth-child(8) { letter-spacing: -4px; } //t
            &:nth-child(9) { letter-spacing: -4px; } //o
            &:nth-child(10) { letter-spacing: -4px; } //u
            &:nth-child(11) { letter-spacing: -4px; } //c
            &:nth-child(12) { letter-spacing: -8px; } //h
            // &:nth-child(13) { letter-spacing: -1px; } // 
            &:nth-child(14) { letter-spacing: -2px; } //w
            &:nth-child(15) { letter-spacing: -3px; } //i
            &:nth-child(16) { letter-spacing: -3px; } //t
            &:nth-child(17) { letter-spacing: -8px; } //h
            // &:nth-child(18) { letter-spacing: -1px; } // 
            &:nth-child(19) { letter-spacing: -4px; } //u
            &:nth-child(20) { letter-spacing: -3px; } //p
            // &:nth-child(21) { letter-spacing: -1px; } //.
        }
    }
}
.is-catch-confirm {
    letter-spacing: -.5vw;
    @include sp {
        letter-spacing: -1vw;
    }
}
.is-catch-complete {
    letter-spacing: -.5vw;
    @include sp {
        letter-spacing: -1vw;
    }
}
.is-menushow {
    .hamburger {
        span {
            width: spw(16);
            background: transparent;
            &::before, &::after {
                left: spw(2);
            }
            &::before {
                top: spw(-5);
                transform: rotate(38deg);
            }
            &::after {
                bottom: spw(-5);
                transform: rotate(-38deg);
            }
        }
    }
    nav {
        top: spw(65);
        opacity: 1;
        pointer-events: initial;
    }
}