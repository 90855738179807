section {
    padding-bottom: pcw(160);
    background: var(--c-b-gry);
    h2, h3, h4 {
        @include inter;
    }
    h2 {
        margin-bottom: pcw(20);
    }
    h3 {
        margin-bottom: pcw(40);
        font-size: pcw(104);
        letter-spacing: -1px;
    }
    h4 {
        font-weight: 500;
    }
    @include sp {
        padding-bottom: spw(60);
        h2 {
            margin-bottom: spw(15);
        }
        h3 {
            margin-bottom: spw(20);
            line-height: 1.2;
            font-size: spw(60);
        }
    }
}
.page {
    &-index {
        .intro {
            padding-top: pcw(218);
            padding-bottom: pcw(115);
            position: relative;
            h2 {
                line-height: .9;
                font-size: pcw(168);
            }
            .lead {
                font-size: pcw(18);
                font-weight: 500;
                overflow: hidden;
            }
            .scr {
                font-size: pcw(12);
                @include inter;
                transform: rotate(90deg);
                position: absolute;
                top: pcw(245);
                right: pcw(20);
                &::before, &::after {
                    @include content;
                    width: pcw(237);
                    height: 2px;
                    background: var(--c-gry);
                    top: calc(50% - 1px);
                    left: pcw(64);
                }
                &::after {
                    background: var(--c-blk);
                    animation: ani-scr 1.5s linear infinite;
                }
            }
            @include sp {
                padding-top: spw(120);
                padding-bottom: spw(53);
                h2 {
                    line-height: .9;
                    font-size: spw(73);
                }
                p {
                    font-size: max(spw(14), 14px);
                }
                .scr {
                    font-size: spw(12);
                    top: spw(440);
                    right: initial;
                    left: spw(0);
                    &::before, &::after {
                        width: spw(237);
                        left: spw(60);
                    }
                }
            }
        }
        .works {
            .heads {
                @include sp {
                    margin-bottom: spw(104);
                }
            }
        }
    }
    &-about {
        .intro {
            padding: pcw(200) 0 pcw(80);
            .lead {
                margin-bottom: pcw(80);
                font-size: pcw(18);
                font-weight: 500;
            }
            @include sp {
                padding: spw(120) 0 spw(64);
                .lead {
                    margin-bottom: spw(40);
                    font-size: spw(16);
                }
            }
        }
        .works {
            padding-bottom: 0;
            .list {
                margin-bottom: 0;
            }
        }
    }
    &-works {
        .works {
            padding-top: pcw(200);
            padding-bottom: 0;
            .list {
                margin-bottom: 0;
                & + .btns {
                    margin-top: pcw(40);
                }
            }
            @include sp {
                padding-top: spw(120);
                .list {
                    & + .btns {
                        margin-top: spw(40);
                    }
                }
            }
        }
    }
    &-recruit {
        .recruit {
            padding: pcw(200) 0;
            @include sp {
                padding: spw(120) 0 spw(64);
            }
        }
        .heads {
            position: relative;
            .step {
                position: absolute;
                bottom: 0;
                right: 0;
                @include flex($h: flex-end);
                gap: pcw(40);
                li {
                    counter-increment: num;
                    font-size: pcw(14);
                    text-align: center;
                    color: var(--c-l-gry);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    line-height: 1;
                    &.cr {
                        color: var(--c-blk);
                    }
                    &::before {
                        content: counter(num, decimal-leading-zero);
                        @include inter($w: 600);
                        font-size: pcw(28);
                    }
                }
            }
            @include sp {
                margin-bottom: spw(60);
                .step {
                    margin-top: spw(60);
                    position: static;
                    justify-content: flex-start;
                    gap: spw(28);
                    li {
                        font-size: max(spw(14), 14px);
                        flex-direction: row;
                        &::before {
                            font-size: spw(18);
                        }
                    }
                }
            }
        }
        // dt, dd {
        //     :not(:last-of-type) {
        //         border-bottom: 1px solid var(--c-gry);
        //     }
        // }
        @include sp {
            dd {
                &:last-child {
                    margin-bottom: spw(40);
                }
            }
        }
    }
    &-company {
        .company {
            padding: pcw(200) 0 0;
            @include sp {
                padding: spw(120) 0 0;
                .lead {
                    margin-bottom: spw(64);
                }
            }
        }
    }
    &-contact {
        .contact {
            padding: pcw(200) 0;
            @include sp {
                padding: spw(120) 0;
            }
        }
        .heads {
            position: relative;
            .step {
                position: absolute;
                bottom: 0;
                right: 0;
                @include flex($h: flex-end);
                gap: pcw(40);
                li {
                    counter-increment: num;
                    font-size: pcw(14);
                    text-align: center;
                    color: var(--c-l-gry);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    line-height: 1;
                    &.cr {
                        color: var(--c-blk);
                    }
                    &::before {
                        content: counter(num, decimal-leading-zero);
                        @include inter($w: 600);
                        font-size: pcw(28);
                    }
                }
            }
            @include sp {
                margin-bottom: spw(60);
                .step {
                    margin-top: spw(60);
                    position: static;
                    justify-content: flex-start;
                    gap: spw(28);
                    li {
                        font-size: max(spw(14), 14px);
                        flex-direction: row;
                        &::before {
                            font-size: spw(18);
                        }
                    }
                }
            }
        }
    }
    &-policy {
        .policy {
            padding: pcw(200) 0 0;
            @include sp {
                padding: spw(120) 0 0;
            }
        }
    }
    &-secondary {
        padding-top: pcw(200);
        @include sp {
            padding-top: spw(100);
        }
    }
}
.about {
    padding-bottom: pcw(200);
    @include flex($v: flex-end);
    flex-direction: row-reverse;
    .txt {
        margin-left: pcw(80);
        padding-bottom: pcw(60);
        width: pcw(550);
        p {
            margin-bottom: 40px;
            line-height: 2.5;
        }
    }
    .thumb {
        width: pcw(730);
        height: pcw(680);
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        // background-color: var(--c-b-gry);
        // background-image: radial-gradient(var(--c-l-gry) 15%, transparent 15%);
        // background-size: pcw(10) pcw(10);
        // border-radius: pcw(28) 0 0 pcw(28);
        // overflow: hidden;
        // position: relative;
        // img {
        //     width: pcw(1400);
        //     height: pcw(1400);
        //     position: absolute;
        //     top: calc(50% - pcw(680));
        //     left: calc(50% - pcw(700));
        // }
    }
    @include sp {
        padding-bottom: spw(104);
        align-items: center;
        flex-direction: column;
        .txt {
            margin: 0 auto;
            padding-bottom: 0;
            width: spw(335);
        }
        .thumb {
            margin-left: auto;
            margin-bottom: spw(104);
            width: spw(300);
            height: spw(420);
            // background-size: spw(6) spw(6);
            // border-radius: spw(14) 0 0 spw(14);
            // img {
            //     width: spw(916);
            //     height: spw(876);
            //     top: calc(50% - spw(438));
            //     left: calc(50% - spw(458));
            // }
        }
    }
}
.works {
    .list {
        margin-bottom: pcw(80);
        border-top: 1px solid var(--c-gry);
    }
    .block {
        padding: pcw(80) pcw(34);
        border-bottom: 1px solid var(--c-gry);
        @include flex($v: flex-start);
        .imgs {
            width: pcw(520);
            img {
                width: 100%;
                box-shadow: 1px 8px 12px rgba(0, 0, 0, .3);
            }
        }
        .txts {
            width: pcw(570);
            .client {
                margin-bottom: pcw(30);
                @include inter;
                @include flex($h: flex-start, $v: flex-start);
            }
            .label {
                margin-right: 12px;
                padding: 4px 16px;
                text-align: center;
                line-height: 1;
                border: 1px solid var(--c-blk);
                border-radius: 4px;
            }
            .name {
                max-width: calc(100% - 110px);
                font-weight: 500;
            }
            .catch {
                margin-bottom: pcw(12);
                font-size: pcw(24);
                font-weight: 500;
            }
            .lead {
                margin-bottom: pcw(28);
            }
            .tags {
                margin-bottom: pcw(40);
                line-height: 2;
                font-size: pcw(12);
                font-weight: 600;
                @include inter;
                @include flex($h: flex-start);
                gap: 3px;
                li {
                    &:not(:last-child) {
                        &::after {
                            content: " /";
                            color: var(--c-blk);
                        }
                    }
                }
                .unsupported {
                    color: var(--c-l-gry);
                }
            }
        }
    }
    .btns {
        a {
            margin-left: auto;
        }
    }
    .detail {
        padding-top: pcw(80);
        border-top: 1px solid var(--c-gry);
        .above {
            padding: 0 pcw(120) pcw(80);
            border-bottom: 1px solid var(--c-gry);
            .client {
                margin-bottom: pcw(30);
                @include inter;
                @include flex($h: flex-start, $v: flex-start);
            }
            .label {
                margin-right: 12px;
                padding: 4px 16px;
                text-align: center;
                line-height: 1;
                border: 1px solid var(--c-blk);
                border-radius: 4px;
            }
            .name {
                max-width: calc(100% - 110px);
            }
            .catch {
                margin-bottom: pcw(12);
                font-size: pcw(24);
                font-weight: 500;
            }
            .lead {
                margin-bottom: pcw(40);
            }
            .mv {
                margin-bottom: pcw(60);
                width: 100%;
                box-shadow: 1px 8px 12px rgba(0, 0, 0, .3);
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .txt {
                margin-bottom: pcw(60);
            }
            .tags {
                margin-bottom: pcw(60);
                line-height: 2;
                font-size: pcw(20);
                font-weight: 600;
                @include inter;
                @include flex($h: flex-start);
                gap: 5px;
                li {
                    &:not(:last-child) {
                        &::after {
                            content: " /";
                            color: var(--c-blk);
                        }
                    }
                }
                .unsupported {
                    color: var(--c-l-gry);
                }
            }
            .url {
                @include flex($h: flex-start);
                dt {
                    margin-right: 5px;
                }
                a {
                    color: var(--c-l-gry);
                    text-decoration: underline;
                }
            }
        }
        .below {
            padding: pcw(80) pcw(120);
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: pcw(20);
                font-size: pcw(24);
            }
            p {
                margin-bottom: pcw(40);
            }
            img {
                width: 100%;
                height: auto;
                box-shadow: 1px 8px 12px rgba(0, 0, 0, .3);
            }
        }
        .list {
            padding-top: pcw(60);
            h2 {
                font-size: pcw(32);
            }
        }
    }
    @include sp {
        .list {
            margin-bottom: spw(40);
        }
        .block {
            padding: spw(40) 0;
            flex-direction: column;
            gap: spw(40);
            .imgs {
                width: 100%;
            }
            .txts {
                width: 100%;
                .client {
                    margin-bottom: spw(16);
                    font-size: spw(12);
                }
                .catch {
                    margin-bottom: spw(16);
                    font-size: spw(20);
                }
                .lead {
                    margin-bottom: spw(24);
                    font-size: spw(14);
                }
                .tags {
                    margin-bottom: spw(24);
                    font-size: spw(12);
                    font-weight: 600;
                }
            }
            a {
                width: 100%;
                height: spw(40);
                line-height: spw(40);
            }
        }
        .btns {
            a {
                width: 100%;
                height: spw(40);
                line-height: spw(40);
            }
        }
        .detail {
            padding-top: spw(40);
            .above {
                padding: 0 0 spw(40);
                .client {
                    margin-bottom: spw(16);
                }
                .catch {
                    margin-bottom: spw(24);
                    font-size: spw(24);
                }
                .lead {
                    margin-bottom: spw(40);
                }
                .mv {
                    margin-bottom: spw(40);
                }
                .txt {
                    margin-bottom: spw(40);
                }
                .tags {
                    margin-bottom: spw(40);
                    font-size: spw(12);
                }
            }
            .below {
                padding: spw(40) 0;
                h1, h2, h3, h4, h5, h6 {
                    margin-bottom: spw(24);
                    font-size: spw(20);
                }
                p {
                    margin-bottom: spw(32);
                }
            }
            .list {
                padding-top: spw(40);
                h2 {
                    font-size: spw(32);
                }
                .block {
                    &:first-of-type {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}
.office {
    padding: pcw(200) 0;
    @include flex($h: center);
    flex-direction: column;
    gap: pcw(80);
    &::before {
        @include content;
        width: 100vw;
        height: 100vh;
        background: url(../img/bg/office-pc.png) no-repeat center center;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &::after {
        @include content;
        width: 100vw;
        height: 100vh;
        background: var(--c-blk);
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.5;
        z-index: -1;
    }
    .logo {
        width: pcw(960);
        height: auto;
    }
    .btn {
        color: var(--c-wht);
        border: 1px solid var(--c-wht);
        &::before {
            background: transparent;
        }
        &::after {
            background: var(--c-wht);
        }
    }
    @include sp {
        padding: spw(254) spw(20);
        gap: spw(40);
        &::before {
            background-image: url(../img/bg/office-sp.png);
        }
        .logo {
            width: spw(335);
        }
    }
}
.cvs {
    padding: pcw(120) 0;
    background: var(--c-b-gry);
    @include flex($h: center);
    gap: pcw(40);
    .btn {
        padding: 0 pcw(100) 0 pcw(40);
        width: pcw(600);
        height: pcw(120);
        line-height: pcw(120);
        text-align: center;
        border: 1px solid var(--c-blk);
        border-radius: pcw(120);
        position: relative;
        @include ts;
        span {
            font-size: pcw(45);
            font-weight: 600;
            @include inter;
            letter-spacing: -1px;
            &::after {
              @include content;
              width: pcw(26);
              height: pcw(26);
              background: url(../img/ico/arrow-grn.svg) no-repeat 0 0;
              background-size: contain;
              top: calc(50% - pcw(13));
              right: pcw(60);
              filter: brightness(0);
              @include ts;
            }
        }
        @include pc {
            &:hover {
                color: var(--c-grn);
                border-color: var(--c-grn);
                span {
                    &::after {
                        filter: initial;
                    }
                }
            }
        }
    }
    @include sp {
        padding: spw(64) 0;
        gap: spw(24);
        .btn {
            padding: 0 spw(50);
            width: spw(335);
            height: spw(67);
            line-height: spw(67);
            border-radius: spw(67);
            span {
                font-size: spw(24);
                &::after {
                  width: spw(15);
                  height: spw(15);
                  top: calc(50% - spw(7));
                  right: spw(24);
                }
            }
        }
    }
}
.bg {
    padding: pcw(260) 0;
    @include flex($h: center);
    flex-direction: column;
    &::before {
        @include content;
        width: 100vw;
        height: 100vh;
        background: url(../img/bg/office-pc.png) no-repeat center center;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
    &::after {
        @include content;
        width: 100vw;
        height: 100vh;
        background: var(--c-blk);
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0.5;
        z-index: -1;
    }
    .logo {
        width: pcw(960);
        height: auto;
    }
    @include sp {
        padding: spw(294) 0;
        &::before {
            background-image: url(../img/bg/office-sp.png);
        }
        .logo {
            width: spw(335);
        }
    }
}
.method {
    padding: pcw(80) 0 pcw(160);
    .lead {
        margin-left: pcw(240);
        margin-bottom: pcw(204);
    }
    .cols {
        @include flex($v: flex-start);
    }
    h2 {
        width: pcw(240);
    }
    ul {
        width: calc(100% - pcw(240));
        @include flex($h: flex-start, $v: flex-start);
        gap: pcw(100) pcw(80);
        li {
            width: calc(30% - pcw(40));
        }
        dt {
            margin-bottom: pcw(20);
            line-height: 1;
            letter-spacing: -1px;
            font-size: pcw(40);
            @include inter;
        }
    }
    @include sp {
        padding-bottom: spw(80);
        .lead {
            margin: 0 0 spw(80);
        }
        .cols {
            flex-direction: column;
        }
        h2 {
            width: 100%;
        }
        ul {
            width: 100%;
            border-top: 1px solid var(--c-gry);
            flex-direction: column;
            gap: 0;
            li {
                padding: spw(64) 0;
                width: 100%;
                border-bottom: 1px solid var(--c-gry);
            }
            dt {
                margin-bottom: spw(20);
                font-size: spw(36);
            }
        }
    }
}
.capability {
    // background: transparent;
    // position: relative;
    // &::after {
    //     @include content;
    //     width: 100%;
    //     height: pcw(160);
    //     background: var(--c-b-gry);
    //     left: 0;
    //     bottom: 0;
    // }
    h2 {
        margin-bottom: 0;
        padding-left: pcw(80);
        padding-bottom: pcw(40);
        background: var(--c-b-gry);
    }
    ul {
        background: transparent;
        border-top: 1px solid var(--c-gry);
        @include flex;
        li {
            width: calc(100% / 4);
            height: pcw(350);
            font-size: pcw(24);
            letter-spacing: -1px;
            text-align: center;
            background: var(--c-b-gry);
            border-bottom: 1px solid var(--c-gry);
            @include inter;
            @include flex($h: center);
            @include pc {
                &:not(:nth-child(4n)) {
                    border-right: 1px solid var(--c-gry);
                }
            }
        }
    }
    @include sp {
        // &::after {
        //     height: spw(104);
        // }
        h2 {
            padding-left: spw(20);
            padding-bottom: spw(40);
        }
        ul {
            li {
                width: calc(100% / 2);
                height: spw(187);
                font-size: spw(18);
                &:not(:nth-child(2n)) {
                    border-right: 1px solid var(--c-gry);
                }
            }
        }
    }
}
.company, .contact, .recruit {
    .cols {
        border-top: 1px solid var(--c-gry);
        align-items: flex-start;
        &:last-child {
            border-bottom: 1px solid var(--c-gry);
        }
        h4 {
            margin-bottom: pcw(40);
            padding-top: pcw(50);
            width: pcw(320);
            font-size: pcw(40);
            font-weight: 600;
        }
        dl {
            width: calc(100% - pcw(330));
        }
    }
    dl {
        @include flex($v: stretch);
    }
    dt, dd {
        padding: pcw(80) 0;
        // border-bottom: 1px solid var(--c-gry);
        &:not(:last-of-type) {
            border-bottom: 1px solid var(--c-gry);
        }
    }
    dt {
        width: 25%;
        &.catch {
            margin-bottom: 5px;
            padding-top: pcw(60);
            padding-bottom: 0;
            width: 100%;
            font-size: pcw(24);
            font-weight: 600;
            border-bottom: none;
            & + dd {
                padding-top: 0;
                padding-bottom: pcw(60);
                width: 100%;
            }
        }
    }
    dd {
        width: calc(100% - 25%);
    }
    .border {
        margin-top: 20px;
        padding: 5px;
        width: pcw(682);
        min-width: pcw(682);
        max-width: pcw(900);
        height: pcw(56);
        border: 1px solid #aaa;
        border-radius: 4px;
    }
    .btns {
        margin-left: pcw(330);
        padding: pcw(40) 0 pcw(80);
        width: calc(100% - pcw(330));
        // border-top: 1px solid var(--c-gry);
    }
    @include sp {
        .cols {
            flex-direction: column;
            h4 {
                margin: spw(40) 0;
                padding-top: 0;
                width: 100%;
                font-size: spw(24);
            }
            dl {
                width: 100%;
            }
        }
        dt, dd {
            padding: 0;
        }
        dt {
            margin: 0 0 spw(12);
            padding: 0 0 spw(8);
            width: 100%;
            border-bottom: 1px solid var(--c-gry);
            &.catch {
                margin-bottom: spw(24);
                padding-top: 0;
                font-size: spw(20);
                & + dd {
                    padding-bottom: spw(40);
                }
            }
        }
        dd {
            margin-bottom: spw(40);
            // padding-bottom: spw(40);
            width: 100%;
            border-bottom: none !important;
            // &:not(:last-of-type) {
            //     border-bottom: 1px solid var(--c-gry);
            // }
        }
        .border {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: spw(40);
        }
        .btns {
            margin-left: 0;
            padding: 0 0 spw(60);
            width: 100%;
            border-top: none;
            a {
                width: 100%;
            }
        }
    }
    .links {
        margin-top: pcw(20);
        @include flex;
        a {
            font-size: pcw(24);
            line-height: 1.2;
            letter-spacing: -1px;
            @include inter;
            cursor: pointer;
            position: relative;
            @include ts;
            @include pc {
                span {
                    padding-right: pcw(40);
                    animation: ani-mouseover .3s forwards;
                    position: relative;
                    &::after {
                        @include content;
                        width: pcw(28);
                        height: pcw(28);
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: 100%;
                        top: calc(50% - pcw(14));
                        right: 0;
                    }
                }
                .hv-gry {
                    color: var(--c-l-gry);
                    cursor: initial;
                    &::after {
                        background-image: url(../img/ico/arrow-s-gry.svg);
                    }
                }
                .hv-blk {
                    // animation: ani-mouseover .3s forwards;
                    @include ts;
                    &::after {
                        background-image: url(../img/ico/arrow-s-grn.svg);
                        filter: brightness(0);
                        @include ts;
                    }
                }
                .hv-grn {
                    color: var(--c-grn);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    text-shadow:
                        -0.1px -0.1px var(--c-grn),
                        0.1px -0.1px var(--c-grn),
                        -0.1px -0.1px var(--c-grn),
                        -0.1px 0.1px var(--c-grn);
                    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
                    animation: ani-mouseout .3s forwards;
                    &::after {
                        background-image: url(../img/ico/arrow-s-grn.svg);
                    }
                }
                &:hover {
                    .hv-blk {
                        color: var(--c-grn);
                        &::after {
                            filter: brightness(1);
                        }
                        // animation: ani-mouseout .3s forwards;
                    }
                    // .hv-grn {
                    //     animation: ani-mouseover .3s forwards;
                    // }
                }
            }
        }
        @include sp {
            margin-top: spw(40);
            flex-direction: column;
            align-items: stretch;
            gap: spw(20);
            a {
                padding: 0 spw(30) 0 spw(20);
                width: 100%;
                height: spw(40);
                line-height: spw(40);
                font-size: spw(16);
                border: 1px solid var(--c-blk);
                border-radius: spw(40);
                display: block;
                .hv-grn {
                    display: none;
                }
                &::after {
                    @include content;
                    width: spw(10);
                    height: spw(10);
                    background: url(../img/ico/arrow-xs.svg) no-repeat 0 0;
                    background-size: contain;
                    top: calc(50% - spw(4));
                    right: spw(10);
                }
                &:has(.hv-gry) {
                    color: var(--c-l-gry);
                    border-color: var(--c-l-gry);
                    &::after {
                        filter: brightness(4) grayscale(100%);
                    }
                }
            }
        }
    }
}
.form,
.contact {
    dl {
        border-top: 1px solid var(--c-gry);
        @include flex($v: stretch);
    }
    dt, dd {
        padding: pcw(80) 0;
        border-bottom: 1px solid var(--c-gry);
    }
    dt {
        width: pcw(300);
        font-size: pcw(14);
    }
    dd {
        width: calc(100% - pcw(300));
        .url {
            margin-top: 20px;
            padding: 10px;
            height: 60px;
            border: 1px solid #aaa;
            border-radius: 5px;
        }
    }
    @include sp {
        h3 {
            line-height: 1;
        }
        dl {
            flex-direction: column;
        }
        dt, dd {
            padding: 0;
        }
        dt {
            padding: spw(40) 0 spw(30);
            width: 100%;
            font-size: max(spw(14), 14px);
            border-top: 1px solid var(--c-gry);
            border-bottom: none !important;
        }
        dd {
            margin-bottom: 0;
            padding: 0 0 spw(40);
            width: 100%;
            border-bottom: 1px solid var(--c-gry);
            input[type=text],
            input[type=email] {
                width: 100%;
                max-width: 100%;
            }
            textarea {
                height: spw(200);
            }
        }
    }
}
.job-detail {
    text-align: center;
    h3 {
        margin-bottom: pcw(28);
        font-size: pcw(32);
    }
    .catch {
        margin-bottom: pcw(20);
        font-size: pcw(20);
    }
    .txt {
        margin-bottom: pcw(40);
    }
    .frame {
        margin-bottom: pcw(40);
        padding: 0 pcw(20);
        height: pcw(323);
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 2px; 
            height: 12px; 
        }
        &::-webkit-scrollbar-track {
            background: #E0E0E0;
        }
        &::-webkit-scrollbar-thumb {
            background: #26212F;
        }
        &::-webkit-scrollbar-button {
            background: #fff;
            height: 0;
        }
    }
    dl {
        width: 100%;
        text-align: left;
        border-top: 1px solid var(--c-gry);
        @include flex($h: flex-start, $v: stretch);
    }
    dt, dd {
        padding: pcw(20) 0;
        border-bottom: 1px solid var(--c-gry);
    }
    dt {
        width: pcw(120);
    }
    dd {
        width: calc(100% - pcw(120));
    }
    .btn {
        margin: 0 auto;
        width: pcw(312);
        height: pcw(56);
        line-height: pcw(56);
    }
    @include sp {
        h3 {
            margin-bottom: spw(24);
            font-size: spw(24);
        }
        .catch {
            margin-bottom: spw(20);
            font-size: spw(16);
        }
        .txt {
            margin-bottom: spw(40);
        }
        .frame {
            margin-bottom: spw(28);
            padding: 0 spw(10);
            height: 50vh;
        }
        dl {
            border-top: none;
            flex-direction: none;
        }
        dt, dd {
            padding: 0;
        }
        dt {
            margin-bottom: spw(10);
            padding-bottom: spw(5);
            width: 100%;
        }
        dd {
            width: 100%;
            border-bottom: none;
        }
        .btn {
            width: calc(100% - spw(20));
            height: spw(36);
            line-height: spw(36);
        }
    }
}
.form {
    .contents {
        margin-bottom: pcw(80);
        h4 {
            margin-bottom: pcw(40);
            font-size: pcw(18);
        }
        p {
            margin-bottom: pcw(20);
        }
        @include sp {
            margin-bottom: spw(60);
            h4 {
                margin-bottom: spw(40);
                font-size: spw(18);
            }
            p {
                margin-bottom: spw(20);
            }
        }
    }
    dl {
        margin-bottom: pcw(40);
        border-top: 1px solid var(--c-gry);
        // border-bottom: 1px solid var(--c-gry);
        @include sp {
            margin-bottom: spw(40);
        }
    }
    .notice {
        margin-bottom: pcw(40);
        font-weight: 600;
    }
    .error {
        color: var(--c-red);
        font-weight: 400 !important;
    }
    .frame {
        margin-bottom: pcw(40);
        padding-right: pcw(30);
        height: pcw(320);
        overflow-y: scroll;
        a {
            color: #11c;
            text-decoration: underline;
            @include pc {
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &::-webkit-scrollbar {
            width: 2px; 
            height: 12px; 
        }
        &::-webkit-scrollbar-track {
            background: #E0E0E0;
        }
        &::-webkit-scrollbar-thumb {
            background: #26212F;
        }
        &::-webkit-scrollbar-button {
            background: #fff;
            height: 0;
        }
        p {
            margin-bottom: pcw(20);
        }
    }
    .btns {
        @include flex($h: flex-end, $v: flex-end);
        gap: pcw(80);
        line-height: 1;
        .back {
            border-bottom: 1px solid var(--c-blk);
            @include pc {
                &:hover {
                    border-bottom: none;
                }
            }
        }
    }
    .btn {
        font-size: pcw(16);
        @include sp {
            width: 100%;
            height: spw(40);
            line-height: spw(40);
            font-size: spw(16);
            border-radius: spw(40);
        }
        &-back {
            font-size: pcw(20);
            transform: translateY(pcw(-15));
            input[type=button] {
                border-bottom: 1px solid var(--c-blk);
            }
            @include sp {
                font-size: max(spw(14), 14px);
            }
        }
        &-send {
            // margin-left: auto;
            padding-right: pcw(140);
            font-size: pcw(104);
            @include inter;
            text-align: right;
            display: block;
            position: relative;
            cursor: pointer;
            @include ts($duration: .01s);
            &:has(:disabled) {
                opacity: 0.3;
                pointer-events: none;;
            }
            input[type=submit] {
                letter-spacing: -3px;
                @include ts;
            }
            &::before, &::after {
                @include content;
                width: pcw(120);
                height: pcw(120);
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: 100%;
                top: calc(50% - pcw(60));
                right: 0;
                @include ts;
            }
            &::before {
                background-image: url(../img/ico/arrow-l-grn.svg);
            }
            &::after {
                background-image: url(../img/ico/arrow-l.svg);
            }
            @include pc {
                &:hover {
                    color: var(--c-grn);
                    &::after {
                        opacity: 0;
                    }
                }
            }
        }
    }
    @include sp {
        .requied {
            &::after {
                margin-left: spw(10);
                font-size: spw(12);
            }
        }
        .notice {
            margin-bottom: spw(40);
            text-align: center;
        }
        .frame {
            margin-bottom: spw(32);
            height: spw(300);
            p {
                margin-bottom: spw(20);
            }
        }
        .tac {
            text-align: center;
        }
        .agree {
            // width: 100%;
            text-align: center;
            display: block;
            & + .error {
                text-align: center;
            }
        }
        .btns {
            flex-direction: column-reverse;
            gap: spw(40);
        }
        .btn {
            &-send {
            padding-right: spw(80);
            font-size: spw(60);
                &::before, &::after {
                    width: spw(64);
                    height: spw(64);
                    top: calc(50% - spw(32));
                }
            }
        }
    }
}
.requied {
    &::after {
        content: "※必須";
        margin-left: pcw(10);
        font-size: pcw(12);
    }
}
.policy {
    .inner {
        padding-right: pcw(240);
    }
    h3 {
        font-size: pcw(18);
        font-weight: 400;
    }
    .block {
        padding-bottom: pcw(60);
    }
    p {
        margin-bottom: pcw(24);
    }
    @include sp {
        .inner {
            padding-right: 0;
        }
        h3 {
            font-size: spw(18);
        }
        .block {
            padding-bottom: spw(60);
        }
        p {
            margin-bottom: spw(24);
        }
    }
}