:root {
	--c-blk: #26212f;
	--c-gry: #d0d0d0;
	--c-l-gry: #acacac;
	--c-b-gry: #f7f7f7;
	--c-wht: #fff;
	--c-red: #d00;
	--c-pnk: #d800ff;
	--c-grn: #10f8de;
	--ff-ns: 'Noto Sans JP', sans-serif; // 100,200,300,400,500,600,700,800,900
	--ff-sm: 'Shippori Mincho', serif; // 400,500,600,700,800
	--ff-cg: 'Cormorant Garamond', serif; // 300,400,500,600,700
}
// 100 Thin
// 200 Extra-light
// 300 Light
// 400 Regular
// 500 Medium
// 600 Semi-bold
// 700 Bold
// 800 Extra-bold
// 900 Black
html, body {
	width: 100%;
	height: 100%;
	// scroll-behavior: smooth;
	// scroll-padding-top: 100px;
}
body {
	line-height: 1.7;
	font-size: pcw(14);
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: normal;
	color: var(--c-blk);
	// background: var(--c-b-gry);
	@include sp {
		font-size: spw(14);
	}
}
// img {
// 	max-width: 100%;
// 	height: auto;
// 	object-fit: contain;
// 	vertical-align: middle;
// }
::selection {
	color: var(--c-blk);
	background: var(--c-grn);
}