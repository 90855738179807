header {
    padding: pcw(40) 0;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .inner {
        @include flex($h: flex-end);
        gap: 0 pcw(60);
    }
    h1 {
        margin-right: auto;
        width: pcw(90);
    }
    nav {
        width: pcw(600);
        ul {
            @include flex($h: flex-end);
            gap: 0 pcw(40);
            // li {
            //     &:nth-child(1) { width: pcw(120); }
            //     &:nth-child(2) { width: pcw(160); }
            //     &:nth-child(3) { width: pcw(100); }
            //     &:nth-child(4) { width: pcw(120); }
            //     &:nth-child(5) { width: pcw(160); }
            // }
            a {
                @include inter;
            }
        }
        @include pc {
            a {
                @include ts;
                &:hover {
                    color: var(--c-grn);
                }
            }
        }
    }
    @include sp {
        padding: spw(20);
        background: var(--c-b-gry);
        .inner {
            gap: spw(8);
        }
        h1 {
            width: spw(80);
        }
        nav {
            padding-top: spw(40);
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            background: var(--c-b-gry);
            pointer-events: none;
            opacity: 0;
            @include ts;
            ul {
                margin: 0 spw(20);
                border-top: 1px solid var(--c-gry);
                align-items: flex-start;
                flex-direction: column;
                li {
                    border-bottom: 1px solid var(--c-gry);
                    align-self: stretch;
                }
                a {
                    padding: spw(40) spw(24) spw(40) 0;
                    display: block;
                    position: relative;
                    &::after {
                      @include content;
                      width: spw(8);
                      height: spw(8);
                      background: url(../img/ico/arrow-xs.svg) no-repeat 0 0;
                      background-size: contain;
                      top: calc(50% - spw(4));
                      right: spw(20);
                      @include ts;
                    }
                }
            }
        }
        .contact {
            width: spw(120);
        }
        .hamburger {
            width: spw(30);
            height: spw(30);
            background: var(--c-wht);
            border-radius: 50%;
            @include flex($h: center);
            span {
                width: spw(12);
                height: 2px;
                background: var(--c-blk);
                position: relative;
                &::before, &::after {
                    @include content;
                    width: 100%;
                    height: 2px;
                    background: var(--c-blk);
                    left: 0;
                    transform-origin: left center;
                    @include ts;
                }
                &::before {
                    top: spw(-4);
                }
                &::after {
                    bottom: spw(-4);
                }
            }
        }
    }
}
footer {
    padding: pcw(80) 0;
    line-height: 2;
    color: var(--c-wht);
    background: var(--c-blk);
    @include inter;
    .inner {
        @include flex($v: flex-start);
    }
    nav {
        @include pc {
            min-width: 30%;
            max-width: 40%;
            ul {
                // height: pcw(160);
                height: 135px;
                @include flex($h:flex-start);
                flex-direction: column;
                gap: pcw(24) 0;
                li {
                    // width: pcw(140);
                    width: 50%;
                }
            }
            a {
                @include ts;
                &:hover {
                    color: var(--c-grn);
                }
            }
        }
    }
    h1 {
        margin-bottom: pcw(10);
        width: pcw(80);
        height: pcw(19);
        filter: brightness(100);
        img {
            width: 100%;
            height: 100%;
            vertical-align: top;
            object-fit: contain;
        }
    }
    .info {
        width: pcw(200);
        line-height: 2.5;
    }
    dl {
        @include flex;
    }
    dt {
        width: pcw(50);
        font-weight: 600;
    }
    dd {
        width: calc(100% - pcw(60));
        font-weight: 600;
    }
    @include sp {
        padding: spw(48) 0;
        .inner {
            flex-direction: column;
        }
        nav {
            width: 100%;
            ul {
                margin-bottom: spw(40);
                text-align: center;
                flex-direction: column;
                gap: 0;
                li {
                    width: 100%;
                }
                a {
                    padding: spw(8);
                    display: inline-block;
                }
            }
        }
        h1 {
            margin: 0 auto spw(30);
            width: spw(100);
            height: spw(24);
        }
        .info {
            width: 100%;
        }
        dl {
            justify-content: center;
            gap: spw(20);
        }
        dt {
            text-align: right;
        }
        dd {
            text-align: left;
        }
        .row {
            &-tel {
                dt {
                    text-align: right;
                    width: 32%;
                }
                dd {
                    text-align: left;
                    width: 57%;
                }
            }
            &-mail {
                dt {
                    text-align: right;
                    width: 30%;
                }
                dd {
                    text-align: left;
                    width: 60%;
                }
            }
        }
        .copy {
            text-align: center;
        }
    }
}
.under {
    footer {
        .inner {
            line-height: 1;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;
            gap: pcw(36);
            h1 {
                margin: 0 pcw(64) 0 0;
            }
            nav {
                width: pcw(200);
                min-width: initial;
                max-width: initial;
                ul {
                    height: initial;
                    li {
                        width: 100%;
                    }
                }
            }
            .col {
                &-tel {
                    margin-left: auto;
                    dd {
                        // width: pcw(140);
                    }
                }
                &-mail {
                    dd {
                        // width: pcw(180);
                    }
                }
            }
            dl {
                flex-wrap: nowrap;
            }
            dt {
                margin-right: 10px;
                padding-right: 10px;
                width: initial;
                border-right: 1px solid var(--c-b-gry);
            }
            dd {
                width: initial;
            }
            @include sp {
                gap: spw(20);
                h1 {
                    margin: 0 0 spw(20);
                }
                nav {
                    width: 100%;
                    ul {
                        margin-bottom: spw(20);
                    }
                }
                .col {
                    &-tel {
                        margin: 0;
                        width: 100%;
                        dt {
                            margin: 0;
                            padding: 0;
                            text-align: right;
                            width: 32%;
                            border-right: none;
                        }
                        dd {
                            text-align: left;
                            width: 57%;
                        }
                    }
                    &-mail {
                        width: 100%;
                        dt {
                            margin: 0;
                            padding: 0;
                            text-align: right;
                            width: 30%;
                            border-right: none;
                        }
                        dd {
                            text-align: left;
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}