// 4桁　動的に変わるもの
// 3桁　メインのレイアウト
// 2桁　ブロック
// 1桁　単一パーツ
.is-cursor {
    z-index: 9999;
}
.mask {
    z-index: 9997;
}
.is-modal {
    z-index: 9998;
}
header {
    z-index: 999;
}