input[type=button], input[type=text], input[type=submit] {
  -webkit-adiappearance: none;
  border: none;
  border-radius: 0;
}
input[type=text], input[type=email], textarea {
  padding: 0;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0;
}
input[type=file] {
  width: 300px;
  @include sp {
    width: 100%;
  }
}
::file-selector-button,
::-webkit-file-upload-button {
  margin-right: 10px;
  padding: 4px 20px;
  color: var(--c-wht);
  background: var(--c-blk);
  border: none;
  border-radius: 4px;
}
textarea {
  height: pcw(200);
}
select {
  padding: 0;
  cursor: pointer;
}
::placeholder {
  color: var(--c-l-gry);
}
// radio、checkboxカスタムの場合
input[type=radio], input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  opacity: 0;
}
label {
  cursor: pointer;
  span {
    padding-left: pcw(30);
    position: relative;
    &::before {
      @include content;
      width: pcw(20);
      height: pcw(20);
      border: 1px solid var(--c-blk);
      border-radius: 0;
      top: calc(50% - pcw(10));
      left: 0;
    }
    &::after {
      @include content;
      width: pcw(14);
      height: pcw(8);
      border: 2px solid var(--c-blk);
      border-top: none;
      border-right: none;
      transform: rotate(-45deg);
      top: calc(50% - pcw(6));
      left: pcw(4);
      opacity: 0;
    }
  }
  &.unable {
    color: var(--c-gry);
    pointer-events: none;
    span {
      &::before {
        border-color: var(--c-gry);
      }
    }
  }
  @include sp {
    span {
      padding-left: spw(30);
      &::before {
        width: spw(20);
        height: spw(20);
        top: calc(50% - spw(10));
      }
      &::after {
        @include content;
        width: spw(14);
        height: spw(8);
        border-width: 2px;
        top: calc(50% - spw(6));
        left: spw(4);
      }
    }
  }
}
span {
  &:has(input[type=radio]:checked),
  &:has(input[type=checkbox]:checked) {
    &::after {
      opacity: 1;
    }
  }
}
label {
  &:has(input[type=radio]:checked),
  &:has(input[type=checkbox]:checked) {
    span {
      &::after {
        opacity: 1;
      }
    }
  }
}
// selectboxカスタムの場合
select {
  -webkit-appearance: none;
  appearance: none;
  overflow: hidden;
  display: block;
}
.select {
  display: inline-block;
  border: 1px solid var(--c-blk);
  border-radius: 0;
  position: relative;
  overflow: hidden;
  &.arrow {
    select {
      padding-right: 20px;
      position: relative;
    }
    &::after {
      @include content;
      border-top: 5px solid var(--c-blk);
      border-right: 5px solid transparent;
      border-bottom: 0;
      border-left: 5px solid transparent;
      right: 15px;
      top: 18px;
    }
  }
}
.mw_wp_form_input {
  .jobs {
    @include inter($w: 400);
    span {
      font-weight: 600;
    }
  }
  .step {
    li {
      &:nth-child(1) {
        color: var(--c-blk) !important;
      }
    }
  }
}
.mw_wp_form_confirm {
  .step {
    li {
      &:nth-child(2) {
        color: var(--c-blk) !important;
      }
    }
  }
  .jobs {
    align-items: flex-start;
  }
}
.mw_wp_form_complete {
  .step {
    li {
      &:nth-child(3) {
        color: var(--c-blk) !important;
      }
    }
  }
  .jobs {
    font-weight: 400;
  }
}
.mw_wp_form_confirm {
  .is-confirm-hide {
    display: none;
  }
}